import React from 'react';

interface SizeProps {
    imgSize?: string;
}

export const LockerDelivery: React.FC<SizeProps> = ({imgSize = "w-8 h-8 xl:w-10 xl:h-10"}) => {
    return (
        <div className={'loading-icon flex justify-center items-center h-[30px] w-[35px] xl:h-[40px] xl:w-[50px] rounded-lg bg-[#DFDFDF]'}>
            <svg className={`p-1 ${imgSize}`}
                 version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="401.000000pt" height="566.000000pt" viewBox="0 0 401.000000 566.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,566.000000) scale(0.100000,-0.100000)"
                   fill="#D7263D" stroke="none">
                    <path d="M1885 5643 c-1065 -92 -1861 -1019 -1784 -2078 27 -378 165 -817 411
                        -1308 313 -626 831 -1382 1409 -2057 160 -188 127 -185 260 -24 566 685 1075
                        1444 1390 2074 382 763 500 1326 387 1850 -178 834 -869 1454 -1717 1540 -97
                        9 -265 11 -356 3z m490 -402 c204 -48 407 -134 567 -241 346 -231 587 -595
                        680 -1023 19 -87 22 -133 22 -307 0 -226 -14 -317 -75 -502 -156 -473 -543
                        -861 -1014 -1017 -187 -62 -279 -76 -510 -75 -179 0 -225 3 -311 22 -328 71
                        -622 235 -848 473 -547 576 -590 1459 -101 2084 250 319 603 528 1015 601 117
                        21 459 12 575 -15z"/>
                    <path d="M1687 4408 c-213 -83 -384 -154 -380 -158 4 -4 70 -32 145 -63 l136
                        -55 379 149 c208 83 381 153 386 158 7 7 -251 122 -270 120 -5 0 -183 -68
                        -396 -151z"/>
                    <path d="M2485 4330 c-38 -16 -213 -85 -388 -153 l-317 -124 177 -73 178 -72
                        200 82 c110 44 280 114 377 153 98 40 178 74 178 77 0 5 -322 141 -330 139 -3
                        -1 -36 -14 -75 -29z"/>
                    <path d="M1192 3608 l3 -541 410 -158 c226 -88 418 -162 428 -165 16 -5 17 24
                        17 524 l0 529 -182 74 -183 75 -7 -124 c-3 -68 -9 -126 -12 -130 -3 -3 -23 14
                        -43 37 -37 44 -50 47 -105 26 -16 -6 -18 5 -20 131 l-3 138 -153 62 -152 63 2
                        -541z"/>
                    <path d="M2598 3953 l-388 -155 0 -529 c0 -291 2 -529 4 -529 2 0 179 71 392
                        159 l389 158 3 527 c1 289 -1 526 -5 525 -5 -1 -182 -71 -395 -156z"/>
                </g>
            </svg>

        </div>
    );
};

