import React, {useState} from 'react';
import {useCheckoutState} from "../modules/Checkout/state/context/CheckoutProvider";
import {useWindowSize} from './useWindowSize';
import {LoadingIcon} from './assets/LoadingIcon';
import {LockerDelivery} from "./assets/LockerDelivery";
import {Gift} from "./assets/Gift";
import {FormatPrice} from "./assets/FormatPrice";

export const CardItem = ({product}) => {
    const {isMobile, isTablet} = useWindowSize();
    const {handleAddToCart, addingToCart} = useCheckoutState();
    const [qty, setQty] = useState(1);

    if (!product) return null;

    const formatPrice = (price) => {
        let parts = price.toFixed(2).split(".");
        let formattedPrice = `${parts[0]}.${parts[1]}`;
        formattedPrice = `${formattedPrice} Lei`;

        return formattedPrice;
    };

    const regularPriceValue = product?.price_range?.minimum_price?.regular_price.value;
    const finalPriceValue = product?.price_range?.minimum_price?.final_price.value;

// Formatted strings for display
    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

// Use the numeric values for arithmetic operations
    const discountPercentage = regularPriceValue && finalPriceValue
        ? ((regularPriceValue - finalPriceValue) / regularPriceValue) * 100
        : 0;

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const msrpValue = parseFloat(findCustomAttributeValue(product?.custom_attributes, 'msrp'));
    const msrpFormatted = msrpValue > 0 ? formatPrice(msrpValue) : null;

    const truncateCombinedStrings = (str1, str2, maxLength) => {
        str1 = str1 || "";
        str2 = str2 || "";

        if (str1 && str2) {
            str1 += " ";
        }

        const totalLength = str1.length + str2.length;

        if (totalLength <= maxLength) return [str1, str2];
        let availableLengthForStr2 = maxLength - str1.length;

        if (availableLengthForStr2 <= 3) return [str1, ""];

        const truncatedStr2 = str2.slice(0, availableLengthForStr2 - 3) + '...';
        return [str1, truncatedStr2];
    };

    const productName = product?.name;
    let cutProductName = '';

    if (productName && productName.length > 70) {
        cutProductName = productName.substring(0, 67) + '...';
    } else {
        cutProductName = productName;
    }

    const h2ProductName = findCustomAttributeValue(product?.custom_attributes, 'h2_product_name');
    const [adjustedProductName, adjustedH2ProductName] = truncateCombinedStrings(cutProductName, h2ProductName, 75);
    const productUrl = `${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`;
    const showAddToCart = findCustomAttributeValue(product?.custom_attributes, 'cu_fara_pret');
    const isInStock = findCustomAttributeValue(product?.custom_attributes, 'is_in_stock');
    const hasCategory = product?.categories?.some(category => category.id === 470); // 470 is the category ID for "resigilate"

    return (
        <div
            className={'relative bg-white px-2 py-2.5 justify-between items-center gap-1 ' +
                ' border-2 xl:hover:border-slate-900 border-white grid grid-rows-2 w-auto h-auto xl:h-[400px]'}>
            <div className={'react-css absolute ml-3 left-0 top-[41%] xl:top-[39%] flex flex-col gap-3 w-auto'}>
                {product?.special_text_promo &&
                new Date(product?.text_to_date) >= new Date() &&
                new Date(product?.text_from_date) <= new Date() ? (
                    <Gift/>
                ) : null}
            </div>
            <div className={'react-css absolute mr-3 right-0 top-[41%] xl:top-[39%] flex flex-col gap-3 w-auto'}>
                {product?.sameday_locker_delivery ? (
                    <LockerDelivery/>
                ) : null}
            </div>
            <a className={'row-span-1 h-[150px] xl:h-[200px] flex justify-center items-center '}
               href={productUrl}
               title={product?.name}
            >
                <img src={product?.customProductImageUrl?.url} alt={product?.small_image?.label}
                     className={'w-5/6 xl:w-4/5 object-contain object-center max-h-[150px] xl:max-h-[190px] text-sm'}
                     loading="lazy"/>
            </a>

            <div className={'row-span-1 grid grid-row-3 gap-2 h-[135px] xl:h-[185px] w-auto'}>
                <a
                    className={'h-[52px] xl:h-[60px] text-black hover:no-underline active:text-black overflow-hidden '}
                    href={productUrl}
                    title={product?.name}
                >
                    <p className={'react-css leading-[16px] xl:leading-5 text-center text-sm xl:text-base font-medium font-family text-custom-black ' +
                        'h-[50px] xl:h-[60px] overflow-hidden'}>
                        {adjustedProductName}
                        <span
                            className={'react-css leading-[16px] xl:leading-5 text-center text-sm xl:text-base font-medium font-family text-custom-black'}>
                            {adjustedH2ProductName}
                        </span>
                    </p>

                </a>
                <div className={'h-[40px] xl:h-[50px] flex flex-col justify-end items-center'}>
                    {regularPriceValue === 0 ? (
                        <div></div>
                    ) : (
                        regularPriceValue === finalPriceValue ? (
                            <div className={'react-css flex flex-col items-center gap-0 xl:gap-1'}>
                                {msrpFormatted && (
                                    <div className={'react-css flex flex-row items-center'}>
                                        <span
                                            className={'react-css text-xs leading-4 xl:leading-4 xl:text-base font-medium font-family'}>{'PRP:'}</span>
                                        <span
                                            className={'react-css text-xs leading-4 xl:leading-4 xl:text-base font-medium font-family ml-2'}>
                                            {' '}{msrpFormatted}
                                        </span>
                                    </div>
                                )}
                                <p className={'react-css text-base xl:text-[22px] font-bold text-custom-red font-family'}>
                                    <FormatPrice price={finalPriceValue} decimalFontSize={'text-sm xl:text-base'}/>
                                    <span
                                        className={'react-css text-[11px] xl:text-base font-medium text-black ml-2 font-family'}>{'cu TVA'}
                                    </span>
                                </p>
                            </div>
                        ) : (
                            <div className={'flex flex-col items-center'}>
                                <div className={'flex flex-row gap-2 items-center'}>
                                    <p className={'react-css text-xs xl:text-base font-medium line-through font-family'}>
                                        {regularPriceFormatted}
                                    </p>
                                    <p className={'h-[20px] pl-2 pr-2 text-white text-xs xl:text-base font-medium text-center bg-custom-red ' +
                                        'flex items-center justify-center font-family'}>
                                        {`- ${(discountPercentage).toFixed(0)}%`}
                                    </p>
                                </div>

                                <p className={'react-css text-base xl:text-[22px] font-bold text-custom-red font-family'}>
                                    <FormatPrice price={finalPriceValue} decimalFontSize={'text-sm xl:text-base'}/>
                                    <span
                                        className={'react-css text-[11px] xl:text-base font-medium text-black ml-2 font-family'}>{'cu TVA'}
                                    </span>
                                </p>
                            </div>
                        )
                    )}
                </div>

                <div className={'flex justify-center'}>
                    {product?.type_id != 'simple' ? (
                        <button
                            className={'h-[35px] xl:h-[45px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                'text-black text-xs xl:text-base font-family font-bold w-4/5 xl:w-full ' +
                                'hover:bg-main-orange transition-all duration-300 ease-in-out'}
                            onClick={() => window.location.href = productUrl}
                        >
                            <span className={'uppercase text-lg font-black'}>{'Vezi detalii'}</span>
                        </button>
                    ) : (
                        <>
                            {showAddToCart != 'Da' || isInStock != 'In Stock' ? (
                                <>
                                    {hasCategory ? (
                                        <p className={'bg-cards-bg w-full h-auto text-center text-sm xl:text-2xl border-0 p-2 font-montserrat font-bold'}>
                                            {'Produsul nu mai este disponibil'}
                                        </p>
                                    ) : (
                                        <p className={'bg-cards-bg w-full h-auto text-center text-sm border-0 font-montserrat font-bold'}>
                                            {showAddToCart == 'Nu' ? 'Produsul nu se mai comercializeaza' : 'La comanda'}
                                        </p>
                                    )}
                                </>

                            ) : (
                                <button
                                    className={'h-[35px] xl:h-[45px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                        'text-black text-base font-family font-bold w-4/5 xl:w-full ' +
                                        'hover:bg-main-orange transition-all duration-300 ease-in-out ' +
                                        'flex justify-center items-center border-0 hover:border-0'}
                                    onClick={() => handleAddToCart({sku: product?.sku, quantity: qty ? qty : 1})}
                                >
                                    {isMobile || isTablet ? (
                                        <>
                                            {product?.sku === addingToCart ? (
                                                <LoadingIcon/>
                                            ) : (
                                                <svg className={''}
                                                     xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                                     viewBox="0 0 576 512">
                                                    <path fill="#000000"
                                                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                          45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                          28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                          24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                          48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                </svg>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {product?.sku === addingToCart ? (
                                                <LoadingIcon/>
                                            ) : (
                                                <>
                                                <span
                                                    className={'font-family text-lg font-black'}>{'Adauga in cos'}</span>
                                                    <svg className={'ml-5'}
                                                         xmlns="http://www.w3.org/2000/svg" height="24" width="26"
                                                         viewBox="0 0 576 512">
                                                        <path fill="#000000"
                                                              d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                              45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                              28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                              24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                              48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                    </svg>
                                                </>
                                            )}
                                        </>
                                    )}
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
