import React from 'react';
import {
    CreditCardIcon,
    InfoIcon,
    CertificateIcon,
    TruckIcon,
    UndoIcon
} from '../../../../../general';

interface FooterLink {
    id: string;
    Icon: React.FC;
    text: string;
    href: string;
    nofollow?: boolean;
}

const footerLinks: FooterLink[] = [
    {
        id: 'cumparaturi_sigure',
        Icon: CreditCardIcon,
        text: 'Cumparaturi 100% sigure',
        href: '/avantaje-cumparaturi-online#cumparaturi-sigure',
        nofollow: true
    },
    {
        id: 'suport_specializat',
        Icon: InfoIcon,
        text: 'Suport specializat',
        href: '/avantaje-cumparaturi-online#suport-specializat'
    },
    {
        id: 'garantie_service',
        Icon: CertificateIcon,
        text: 'Garantie & service',
        href: '/avantaje-cumparaturi-online#returnare'
    },
    {
        id: 'transport_gratuit',
        Icon: TruckIcon,
        text: 'Transport gratuit',
        href: '/avantaje-cumparaturi-online#transport-gratuit'
    },
    {
        id: 'retur_produse',
        Icon: UndoIcon,
        text: 'Retur produse',
        href: '/avantaje-cumparaturi-online#returnare'
    }
];

export const Utils: React.FC = () => {
    return (
        <ul className="flex flex-wrap justify-center items-center gap-4 py-4 bg-white">
            {footerLinks.map(({id, Icon, text, href, nofollow}) => (
                <li key={id} className={'flex items-center justify-center w-[45%] md:w-1/6'}>
                    <a
                        href={href}
                        rel={nofollow ? "nofollow" : undefined}
                        className={'flex flex-col items-center justify-center gap-2 px-4 py-2 text-black hover:text-black ' +
                            'active:text-black visited:text-black focus:text-black'}
                    >
                        <Icon />
                        <p className={'text-center font-family text-sm xl:text-base font-bold'}>{text}</p>
                    </a>
                </li>
            ))}
        </ul>
    );
};
