import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const PriceInfoSkeleton = () => {

    return (
        <div className={'flex flex-col justify-center items-center md:items-start xl:justify-start '}>
            <div className={'w-2/4 h-3 xl:h-5 justify-center xl:justify-start mb-3 xl:mb-5'}>
                <Skeleton width={'100%'} className={'w-full h-4 xl:h-6'} count={1} />
            </div>
            <div className={'w-2/4 h-11 xl:h-12 justify-center xl:justify-start mb-3 xl:mb-5'}>
                <Skeleton width={'100%'} className={'w-full h-11 xl:h-12'}/>
            </div>
        </div>
    );
}
