import React, {useState} from 'react';
import {ExpandButton, Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';
import {ReviewForm} from "./ReviewForm";
import {DetailsSkeleton} from "../Skeleton/DetailsSkeleton";

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}

const preprocessReviews = (reviews: Review[]): Review[] => {
    return reviews.map(review => {
        const dateOnly = review.created_at.split(" ")[0];
        const defaultRating = {
            name: 'default_code',
            value: '5',
        };

        return {
            summary: review.summary,
            text: review.text,
            nickname: review.nickname,
            created_at: dateOnly,
            average_rating: review.ratings_breakdown.length > 0 ? review.average_rating : 5,
            ratings_breakdown: review.ratings_breakdown.length > 0 ? review.ratings_breakdown : [defaultRating],
        };
    });
};

export const Review = () => {
    const {productAttributes, loading} = useProductAttributes() || {};
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    if (loading) {
        return <DetailsSkeleton/>;
    }

    const showExpandButton = productAttributes?.reviews?.items.length > 4;
    const preprocessedReviews = preprocessReviews(productAttributes?.reviews?.items || []);
    const numberOfReviews = preprocessedReviews.length;

    const totalStarsValue = preprocessedReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, rating) => rSum + Number(rating.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = parseFloat((totalStarsValue / numberOfReviews).toFixed(1));

    return (
        <div className={'react-css bg-white mb-2 xl:mb-5 mt-5'}
             id="productReviews">
            <div className={'bg-cards-bg font-custom-black'}>
                <h2
                    className={'font-family font-semibold leading-[50px] font-custom-black text-base pl-10 pr-10'}>
                    {'Evaluari'}
                </h2>
            </div>
            {!productAttributes || !productAttributes?.reviews || productAttributes?.reviews.items.length === 0 ? (
                <ReviewForm/>
            ) : (
                <div className={'react-css overflow-auto max-h-[500px] border-2 border-solid border-cards-bg p-3'}>
                    <div className={'react-css'}>
                        <h2 className={'react-css text-base font-black text-left text-custom-black font-family mb-8 flex'}>
                        <span className={'react-css xl:block hidden mr-2'}>
                            {'Evaluari -'}
                        </span>
                            <span
                                className={'react-css text-custom-black font-family font-bold text-center text-base'}>
                            {productAttributes?.name}
                        </span>
                        </h2>
                    </div>
                    <div
                        className={'react-css border-b-2 mb-8 flex flex-col md:flex-row xl:flex-row items-center justify-start xl:gap-12 gap-5 pb-5'}>
                        <div className={'react-css flex flex-row items-center justify-start gap-5 '}>
                            <p className={'react-css text-custom-black font-family text-6xl xl:text-8xl italic font-black leading-none tracking-normal mr-6'}>
                                {averageStars}
                            </p>
                            <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                                    <span
                                        className={'react-css flex flex-row items-center justify-start gap-1 text-custom-black'}>
                                        <Stars totalStars={averageStars} starSize={'h-10 w-10 xl:h-12 xl:w-12'}/>
                                    </span>
                                <p className={'react-css flex flex-row gap-2 items-center text-custom-black font-family text-xs font-bold leading-tight tracking-normal'}>
                                <span className={'react-css font-family text-xs font-bold'}>
                                    {'din '}{numberOfReviews}
                                </span>
                                    {'Evaluari'}
                                </p>
                            </div>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start gap-3'}>
                            <button onClick={() => window.location.href = '#review_form_react_app'}
                                    className={
                                        "rounded-xl bg-second-btn-bg hover:bg-second-btn-bg focus:bg-second-btn-bg " +
                                        "font-family font-bold text-sm text-center text-white focus:text-white hover:text-white " +
                                        "shadow-second-btn-shadow transform hover:translate-y-1 mt-2 w-full py-3 px-4 xl:mb-0 mb-5 " +
                                        "flex items-center justify-center"
                                    }
                                    type="button"
                            >
                                <p className="react-css">
                                    {'Scrie o evaluare'}
                                </p>
                            </button>
                        </div>
                    </div>

                    {preprocessedReviews.map((review, index) => (
                        <div className={'mb-8 border-b-2 flex flex-col'} key={index}>
                            <div
                                className={'flex xl:flex-col flex-row gap-3 items-start justify-start xl:w-3/12 mr-3 xl:mb-0 mb-3'}>
                            <span
                                className={'react-css font-family font-bold text-custom-black text-sm'}>
                                {review.nickname}
                            </span>
                                <span
                                    className={'react-css text-sm font-family font-normal'}>
                                {review.created_at}
                            </span>
                            </div>
                            <div className={'flex flex-row gap-3 xl:w-9/12 items-center justify-start mb-5'}>
                                <h2 className={'react-css font-family font-bold text-custom-black text-sm'}>{review.summary}</h2>
                                <div className={''}>
                                    {review.ratings_breakdown.map((rating, ratingIndex) => (
                                        <div className={'flex flex-row items-center justify-start space-x-4'}
                                             key={ratingIndex}>
                                            <Stars totalStars={Number(rating.value)} starSize={'h-5 w-5 xl:h-10 xl:w-10'}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <p className={'react-css font-family text-sm font-normal text-custom-black mb-5'}>{review.text}</p>
                        </div>
                    ))}
                    <ReviewForm/>
                </div>
            )}
        </div>
    );
}
