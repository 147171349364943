import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const AddToCartSkeleton = () => {

    return (
        <div className={'flex flex-row items-start justify-center xl:justify-start mb-3 xl:mb-5 gap-3'}>
            <div className={'w-2/6 h-11 xl:h-12 justify-center xl:justify-start'}>
                <Skeleton width={'100%'} className={'w-full h-11 xl:h-12'} count={1} />
            </div>
            <div className={'w-3/6 h-11 xl:h-12 justify-center xl:justify-start'}>
                <Skeleton width={'100%'} className={'w-full h-11 xl:h-12'}/>
            </div>
        </div>
    );
}
