import React from 'react';
import {CategoryButton} from './CategoryButton';
import {Blocks} from './Blocks';
import {CategoriesProductsStateProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";

const queryClient = client();

export const BlackFridayPage: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <CategoriesProductsStateProvider>
                <div className={'mt-5 xl:mt-10 mb-8'}>
                    <a className={'react-css text-white text-base active:text-white focus:text-white decoration-current'}
                       href={`${window.BASE_URL}home`}>{'Inapoi la pagina principala'}</a>
                </div>
                <div>
                    <div className="flex flex-col gap-6 justify-center items-center w-full">
                        <Blocks/>
                    </div>
                    <div>
                        <CategoryButton/>
                    </div>
                </div>
            </CategoriesProductsStateProvider>
        </QueryClientProvider>
    )
};


