import React, {useState} from "react";
import {useProductAttributes} from "../../../state";
import {FormatPrice} from "../../../../../general";
import {PriceInfoSkeleton} from "../Skeleton/PriceInfoSkeleton";

export const PriceInfo = () => {
    const {productAttributes, customAttributes, loading} = useProductAttributes() || {};
    const regularPrice = productAttributes?.price_range?.minimum_price?.regular_price?.value;
    const finalPrice = productAttributes?.price_range?.minimum_price?.final_price?.value;
    const discountPercent = productAttributes?.price_range?.minimum_price?.discount?.percent_off;
    const discount = discountPercent ? Math.round(discountPercent) : null;

    const [showTooltip, setShowTooltip] = useState(false);

    if (loading) {
        return <PriceInfoSkeleton/>
    }

    const formatPrice = (price) => {
        let parts = price.toFixed(2).split(".");
        let formattedPrice = `${parts[0]}.${parts[1]}`;
        formattedPrice = `${formattedPrice} Lei`;

        return formattedPrice;
    };

    const formattedRegularPrice = regularPrice > 0 ? formatPrice(regularPrice) : null;
    const msrpValue = parseFloat(customAttributes?.find(attribute => attribute?.attribute_code === 'msrp')?.value);
    const formattedMsrp = !isNaN(msrpValue) && msrpValue > 0 ? formatPrice(msrpValue) : null;

    return (
        <>
            {productAttributes && productAttributes && (
                <div className={'flex flex-col items-center md:items-start justify-start pl-5'}>
                    {finalPrice < regularPrice ? (
                        <div>
                            <div className={'relative flex flex-row items-center gap-2'}>
                                <svg
                                    className={`w-4 h-4 xl:w-5 xl:h-5`}
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3
                                0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24
                                24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                </svg>
                                {showTooltip && (
                                    <div
                                        className={'max-w-[300px] min-w-[200px] absolute bottom-10 p-2 border rounded-md shadow-lg ' +
                                            'text-white text-lg font-family text-center bg-second-btn-bg'}>
                                        {'Pretul recomandat de producator (PRP) pentru acest produs este: '}{formattedMsrp}
                                    </div>
                                )}
                                <span className={'font-family text-base xl:text-xl font-normal text-custom-black line-through'}>{formattedRegularPrice}</span>
                                <span className={'react-css mr-5 font-family text-base xl:text-xl text-custom-red'}>
                                    {'reducere - '}{discount}{'%'}
                                </span>
                            </div>
                            <span
                                className={'react-css mr-5 font-family text-2xl xl:text-4xl text-custom-red font-bold'}>
                                <FormatPrice price={finalPrice} decimalFontSize={'text-lg xl:text-2xl'}/>
                                <span className={'text-base xl:text-xl font-normal text-custom-black'} >{' TVA Inclus'}</span>
                            </span>
                        </div>
                    ) : (
                        <div className={'flex flex-col items-start'}>
                            {formattedMsrp && (
                                <div className={'relative flex flex-row items-center gap-2 '}>
                                    <svg
                                        className={`w-4 h-4 xl:w-5 xl:h-5`}
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512">
                                        <path
                                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3
                                0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24
                                24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                    </svg>
                                    {showTooltip && (
                                        <div className={'max-w-[300px] min-w-[200px] bg-second-btn-bg absolute bottom-10 p-2 border rounded-md shadow-lg ' +
                                            'text-white text-sm xl:text-lg text-center bg-second-btn-bg'}>
                                            {'Acesta este pretul recomandat de producator (PRP)'}
                                        </div>
                                    )}
                                    <span className={'font-family text-base xl:text-xl font-normal text-custom-black'}>
                                        {'PRP: '}
                                        <span>{formattedMsrp}</span>
                                    </span>
                                </div>
                            )}
                            <span
                                className={'react-css mr-5 font-family text-2xl xl:text-4xl text-custom-red font-bold'}>
                                <FormatPrice price={regularPrice} decimalFontSize={'text-lg xl:text-2xl'}/>
                                <span className={'text-base xl:text-xl font-normal text-custom-black'}>{' TVA Inclus'}</span>
                            </span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
