import React from 'react';
import {ReviewFormInner} from "./ReviewFormInner";
import {ProductViewProvider} from "../../../state";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {FormProvider, useForm} from 'react-hook-form';

export const ReviewForm = () => {
    const queryClient = new QueryClient();
    const form = useForm();

    return (
        <QueryClientProvider client={queryClient}>
            <ProductViewProvider config={{}}>
                <FormProvider {...form}>
                    <ReviewFormInner/>
                </FormProvider>
            </ProductViewProvider>
        </QueryClientProvider>
    );
}
