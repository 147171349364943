import React from 'react';

interface SizeProps {
    imgSize?: string;
}

export const Gift: React.FC<SizeProps> = ({imgSize = "w-8 h-8 xl:w-10 xl:h-10"}) => {
    return (
        <div className={'loading-icon flex justify-center items-center h-[30px] w-[35px] xl:h-[40px] xl:w-[50px] rounded-lg bg-main-orange'}>
            <svg className={`p-1 ${imgSize}`}
                version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                   fill="#000" stroke="none">
                    <path d="M344 490 c-28 -11 -82 -75 -90 -105 -8 -34 -21 -31 -29 5 -10 44 -57
                        90 -93 90 -59 0 -74 -61 -26 -111 l28 -29 -52 0 -52 0 0 -60 c0 -47 3 -60 15
                        -60 12 0 15 -19 15 -110 l0 -110 184 0 c139 0 185 3 189 13 2 6 2 54 -1 105
                        -5 89 -4 93 17 99 18 5 21 12 21 64 l0 59 -45 0 c-25 0 -45 5 -45 10 0 6 4 10
                        10 10 12 0 40 53 40 78 0 40 -46 68 -86 52z m53 -46 c8 -22 -26 -64 -70 -87
                        -47 -24 -62 -15 -41 26 35 69 95 102 111 61z m-223 -25 c14 -17 26 -40 26 -51
                        0 -19 -1 -19 -42 4 -32 17 -44 30 -46 51 -5 38 28 36 62 -4z m44 -136 l3 -33
                        -85 0 -86 0 0 28 c0 16 3 32 7 36 4 4 41 5 83 4 l75 -3 3 -32z m222 2 l0 -35
                        -80 0 -80 0 0 35 0 35 80 0 80 0 0 -35z m-222 -147 l-3 -103 -62 -3 -63 -3 -1
                        76 c-1 41 -1 86 -1 100 0 14 0 27 1 30 1 3 30 5 66 5 l66 0 -3 -102z m192 -3
                        l0 -105 -65 0 -65 0 0 105 0 105 65 0 65 0 0 -105z"/>
                </g>
            </svg>
        </div>
    );
};

