import React from 'react';

export const FooterInfo = () => {

    return (
        <div className="flex flex-row gap-3">
            <div>
                <p className={'text-left text-white font-family text-xs xl:text-sm'}>{'Global Tech SRL'}</p>
                <p className={'text-left text-white font-family text-xs xl:text-sm'}>{'Soseaua Pacurari 86A'}</p>
                <p className={'text-left text-white font-family text-xs xl:text-sm'}>{'Telefon: 0377 100 000'}</p>
                <p className={'text-left text-white font-family text-xs xl:text-sm'}>{'Email: online.sales@tools.store.ro'}</p>
            </div>
        </div>
    );
};
