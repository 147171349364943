import React from 'react';
import {useProductAttributes} from "../../../state";

export const AttachmentsComponent = () => {
    const { configAttachments = { attributes: [] } } = useProductAttributes() || {};
    const { attributes = [] } = configAttachments;

    return (
        <> {configAttachments.attributes.length > 0 && (
            <div className={'react-css pl-5 pb-3 pt-3 block bg-cards-bg xl:bg-inherit'} id={'hexamarvel-attachments'}>
                <ul>
                    {attributes.map((attachment, index) => (
                        <li key={index}
                            className={'flex flex-row gap-2 items-center justify-center md:justify-start xl:justify-start m-0'}>
                            <svg className={'fill-current text-custom-black w-5 h-5'} xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 384 512">
                                <path
                                    d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7
                                0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9
                                0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4
                                33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
                            </svg>
                            <div className={'group relative inline-block hover:cursor-pointer'}>
                                <a className={'react-css text-custom-gray font-family text-sm xl:text-lg leading-4 hover:text-current'}
                                   href={attachment[0].fileUrl} target="_blank" rel="noopener noreferrer">
                                    {index === 0 ? 'Manual utilizare' : attachment[0].name}
                                </a>
                                <span
                                    className={'absolute bg-white max-w-[360px] min-w-[250px] p-4 z-50 hidden md:group-hover:block ' +
                                        'left-[115%] top-[-80%] text-left text-gray-500 leading-7 border border-gray-400 mt-1.5 text-sm xl:text-lg'}>
                                {index === 0 ? 'Copiaza manual utilizare' : attachment[0].name}
                            </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )}
        </>
    );
}
