import React, {useState} from 'react';
import {ProductAttributeProvider} from '../../state';
import {Description} from "./ProductDetails/Description";
import {AdditionalInfo} from "./ProductDetails/AdditionalInfo";
import {Review} from './ProductDetails/Review';
import {ReviewForm} from './ProductDetails/ReviewForm';
import {useWindowSize} from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {RelatedProducts} from "./Related/RelatedProducts";
import {UpsellProducts} from "./Related/UpsellProducts";
import {CrossSellProducts} from "./Related/CrossSellProducts";
import {CheckoutProvider} from "../../../Checkout";
import {AutoRelated} from "./Related/AutoRelated";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductDetails: React.FC<ProductSku> = ({productSku}) => {
    const {isMobile} = useWindowSize();
    const [activeTab, setActiveTab] = useState('description');

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku}>
                    <div>

                        <div>
                            <AutoRelated/>
                            <Description/>
                            <AdditionalInfo/>
                            <UpsellProducts/>
                            <RelatedProducts/>
                            <CrossSellProducts/>
                            <Review/>
                        </div>
                        <p className={'text-custom-black font-family text-sm xl:text-lg mt-3 p-3 xl:p-5'}>
                                Tools.store.ro face eforturi permanente pentru a pastra acuratetea informatiilor din acestă
                        pagina. Rareori, acestea pot contine inadvertente. Fotografiile au caracter informativ si pot
                        contine accesorii neincluse în pachetele standard, iar unele specificatii pot fi modificate de
                        catre producator fara preaviz sau pot contine erori de operare. Toate promotiile prezente in
                        site sunt valabile in limita stocului.
                            </p>
                    </div>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};

