import React, {useState} from 'react';
import {ExpandButton, Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';
import {InfoReviewsSkeleton} from "../Skeleton/InfoReviewsSkeleton ";

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}

const preprocessReviews = (reviews: Review[]): Review[] => {
    return reviews.map(review => {
        const dateOnly = review.created_at.split(" ")[0];
        const defaultRating = {
            name: 'default_code',
            value: '5',
        };

        return {
            summary: review.summary,
            text: review.text,
            nickname: review.nickname,
            created_at: dateOnly,
            average_rating: review.ratings_breakdown.length > 0 ? review.average_rating : 5,
            ratings_breakdown: review.ratings_breakdown.length > 0 ? review.ratings_breakdown : [defaultRating],
        };
    });
};

export const InfoReviews = () => {
    const {productAttributes, loading} = useProductAttributes() || {};
    const preprocessedReviews = preprocessReviews(productAttributes?.reviews?.items || []);
    const numberOfReviews = preprocessedReviews.length;

    const totalStarsValue = preprocessedReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, rating) => rSum + Number(rating.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = parseFloat((totalStarsValue / numberOfReviews).toFixed(1));

    if (loading) {
        return <InfoReviewsSkeleton />;
    }

    return (
        <>
            {!productAttributes || productAttributes?.reviews?.items.length === 0 ? (
                <a
                    className={'react-css flex flex-row items-center justify-center md:justify-start xl:justify-start font-family text-sm xl:text-lg underline gap-2 mb-3 xl:mb-5 ' +
                        'focus:text-custom-black active:text-custom-black visited:text-custom-black'}
                    href={'#review_form_react_app'}>
                    {'Scrie o evaluare'}
                </a>
            ) : (
                <div
                    className={'react-css flex flex-row items-center justify-center md:justify-start xl:justify-start gap-1 text-custom-black mb-5'}>
                    <Stars totalStars={averageStars} starSize={'h-6 w-6'}/>
                    <p className={'react-css text-custom-black font-family text-sm xl:text-lg tracking-normal'}>
                        {averageStars}/5
                    </p>
                    <p className={'react-css flex flex-row font-family gap-2 items-center text-custom-black font-family ' +
                        'text-sm xl:text-lg tracking-normal'}>
                        {' - '}
                        {numberOfReviews > 1 ? (
                            <a className={'hidden xl:flex flex-row items-center focus:text-custom-black active:text-custom-black visited:text-custom-black text-sm xl:text-lg'}
                               href={'#productReviews'}>
                                {numberOfReviews}
                                {' evaluari'}
                            </a>
                        ) : (
                            <a className={'hidden xl:flex flex-row items-center focus:text-custom-black active:text-custom-black visited:text-custom-black text-sm xl:text-lg'}
                               href={'#productReviews'}>
                                {numberOfReviews}
                                {' evaluare'}
                            </a>
                        )}
                    </p>
                    <a
                        className={'react-css flex flex-row items-center font-family text-sm xl:text-lg underline gap-2 ' +
                            'focus:text-custom-black active:text-custom-black visited:text-custom-black'}
                        href={'#review_form_react_app'}>
                        {'Scrie o evaluare'}
                    </a>
                </div>
            )}
        </>
    );
}
