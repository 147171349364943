import React from "react";
import {useProductAttributes} from "../../../state";
import {StockStatusSkeleton} from "../Skeleton/StocStatusSkeleton";


export const StockStatus = () => {
    const {customAttributes, loading} = useProductAttributes() || {};
    const stockStatus = customAttributes?.find(attribute => attribute.attribute_code === 'stock_status')?.value;
    const isInStock = customAttributes?.find(attr => attr.attribute_code === 'is_in_stock')?.value === 'In Stock';
    const showAddToCart = customAttributes?.find(attr => attr.attribute_code === 'cu_fara_pret')?.value == 'Da';

    if (loading) {
        return <StockStatusSkeleton/>
    }

    return (
        <>
            {!isInStock || !showAddToCart ? (
                <div></div>
            ) : (
                <>
                    {stockStatus && (
                        <div className={'flex flex-row justify-center md:justify-start xl:justify-start bg-cards-bg'}>
                            <p className={'text-custom-green font-family text-sm xl:text-lg mr-2'}>{'Stoc: '}</p>
                            <p className={'text-custom-black font-family text-sm xl:text-lg'}>{stockStatus}</p>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
