import React from 'react';
import {usePromoStateProvider} from '../../state';
import {PromoCardItem} from "./PromoCardItem";

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const ItemsByDiscount: React.FC<{ level: number }> = ({level}) => {
    const {
        categoriesData,
        loading,
        discounts,
        firstChildCategoryItems,
        secondChildCategoryItems
    } = usePromoStateProvider();

    if (!categoriesData) {
        return null;
    }

    if (loading)
        return (
            <div
                className={'fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-20 z-50'}>
                <span className={'text-white text-2xl font-bold'}>Loading...</span>
            </div>
        );

    let firstChildDiscount = discounts[level];
    let secondChildDiscount = discounts[level];

    if (level === 1) {
        firstChildDiscount = discounts[2]; //70%
    }

    if (level === 2) {
        firstChildDiscount = discounts[3]; //90%
        secondChildDiscount = discounts[1]; //50%
    }

    const getDiscountLabel = (discount) => {
        if (discount === discounts[2]) {
            return "Pana la 70% reducere in cos!";
        } else if (discount === discounts[3]) {
            return "Pana la 90% reducere in cos!";
        } else {
            return "Pana la 50% reducere in cos!";
        }
    };

    return (
        <div className={'grid grid-cols-2 md:grid-cols-5 xl:grid-cols-5 gap-2 mt-5 mb-5 bg-cards-bg p-2'}>
            {firstChildCategoryItems.map((product) => (
                <PromoCardItem
                    key={product.id}
                    product={product}
                    discount={firstChildDiscount}
                    discountLabel={getDiscountLabel(firstChildDiscount)}
                />
            ))}
            {level === 2 && secondChildCategoryItems.map((product) => (
                <PromoCardItem
                    key={product.id}
                    product={product}
                    discount={secondChildDiscount}
                    discountLabel={getDiscountLabel(secondChildDiscount)}
                />
            ))}
        </div>
    );
};

