import React from 'react';

type ExpandButtonProps = {
    isExpanded: boolean;
    onClick: () => void;
    expandedText: string;
    collapsedText: string;
};

export const ExpandButton: React.FC<ExpandButtonProps> = (
    {
        isExpanded,
        onClick, expandedText,
        collapsedText
    }
) => {
    const expandedIcon = (
        <svg className="h-5 w-5 fill-current absolute left-0" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 10L8 4L14 10" stroke="currentColor" strokeWidth="2"/> {/* Up Arrow */}
        </svg>
    );

    const collapsedIcon = (
        <svg className="h-5 w-5 fill-current absolute left-0" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6L8 12L14 6" stroke="currentColor" strokeWidth="2"/> {/* Down Arrow */}
        </svg>
    );

    return (
        <div className="react-css flex items-center justify-center xl:mb-0 mb-5">
            <button
                onClick={onClick}
                className={
                    "rounded-xl bg-second-btn-bg hover:bg-second-btn-bg focus:bg-second-btn-bg " +
                    "font-family font-bold text-xs text-center text-white focus:text-white hover:text-white " +
                    " transform hover:translate-y-1 mt-2 w-50 p-2 " +
                    "flex items-center justify-center space-x-2"
                }
                type="button"
            >
                <p className="react-css flex items-center justify-center space-x-9 relative">
                    {isExpanded ? expandedIcon : collapsedIcon}
                    <span>{isExpanded ? expandedText : collapsedText}</span>
                </p>
            </button>
        </div>
    );
};
