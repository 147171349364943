import React, {useState} from 'react';
import {Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';
import {useProductViewState} from "../../../state";
import {useFormContext} from 'react-hook-form';
import {ErrorMessage} from "@hookform/error-message";
import {FieldError} from 'react-hook-form';

export const ReviewFormInner = () => {
    const {productAttributes, productName} = useProductAttributes() || {};
    const {handleAddReview, neededRating} = useProductViewState()!;

    if (!productAttributes) return null;

    const {
        register,
        formState: {errors},
        setError,
        clearErrors,
        handleSubmit
    } = useFormContext();

    const [rating, setRating] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validateRating = () => {
        if (rating === 0) {
            setError('starRating', {
                type: 'manual',
                message: 'Te rugam sa selectezi un rating!'
            });
            return false;
        }
        clearErrors('starRating');
        return true;
    };

    const ratingId = neededRating?.id

    const onSubmit = (data) => {
        if (!validateRating()) return;

        const selectedValueId = neededRating?.values[rating - 1]?.value_id;

        console.log('selectedValueId', selectedValueId);
        const dataObj = {
            sku: productAttributes?.sku,
            nickname: data.nickname,
            summary: data.summary,
            text: data.text,
            ratings: [
                {
                    id: ratingId,
                    value_id: selectedValueId
                }
            ]
        };
        handleAddReview(dataObj);
        setFormSubmitted(true);
        setIsModalOpen(true);
    }

    return (
        <div className={'react-css relative bg-white xl:mb-10 mb-5'} id={'review_form_react_app'}>
            {!formSubmitted && (
                <div
                    className={`react-css p-3 xl:p-6`}>
                    <form
                        className={'react-css bg-cards-bg p-4 xl:p-6 block mb-6 mt-6 xl:mt-0'}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className={'react-css flex flex-col items-start justify-start mb-6 gap-2'}>
                            <p className={'react-css text-custom-black font-family text-xs hover:text-current'}>
                                {'Scrie o evaluare pentru:'}
                            </p>
                            <span
                                className={'react-css text-custom-black font-family font-bold text-center text-base'}>
                                {productName}
                            </span>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start mb-7'}>
                        <span
                            className={'react-css font-family font-bold text-custom-black text-xs'}>{'Acorda o nota produsului:'}</span>
                            <span
                                className={'react-css flex flex-row items-center justify-start gap-1 text-custom-black'}>
                   <Stars
                       totalStars={0}
                       starSize={'h-12 w-12'}
                       isInteractive
                       onStarSelect={(selectedRating) => {
                           setRating(selectedRating);
                           if (errors.starRating) {
                               clearErrors('starRating');
                           }
                           console.log('selectedRating', selectedRating)
                       }}
                   />
                                {errors.starRating && <span
                                    className={'error-message text-red-500 text-base'}>{(errors.starRating as FieldError).message}</span>}

                </span>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start mb-6 gap-2'}>
                            <div
                                className={'react-css flex flex-col items-start xl:flex-row xl:items-center justify-between gap-5 w-full mb-6'}>
                                <div className={'react-css flex flex-col items-start justify-start gap-2 w-full'}>
                                    <p className={'react-css text-custom-black font-family text-xs hover:text-current'}>
                                        {'Nume utilizator: '}
                                    </p>
                                    <input
                                        {...register('nickname', {
                                            required: "Nume utilizator este obligatoriu",
                                            maxLength: {
                                                value: 30,
                                                message: "Te rogam sa introduci mai puntin de 30 caractere."
                                            }
                                        })}
                                        placeholder="Numele utilizator"
                                        className={'react-css text-custom-black font-family font-normal text-left text-base ' +
                                            'rounded-xl border border-gray-400 w-full h-12 p-4'}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="nickname"
                                        render={({message}) => <span
                                            className={'error-message text-red-500 text-base'}>{message}</span>}
                                    />
                                </div>
                            </div>
                            <div className={'react-css flex flex-col items-start justify-start gap-2 w-full mb-6'}>
                                <p className={'react-css text-custom-black font-family text-xs  hover:text-current'}>
                                    {'Titlu evaluare: '}
                                </p>
                                <input
                                    {...register('summary', {
                                        required: "Acest camp este obligatoriu",
                                        maxLength: {
                                            value: 50,
                                            message: "Te rogam sa introduci mai puntin de 50 caractere."
                                        }
                                    })}
                                    placeholder="Titlul evaluarii"
                                    className={'react-css text-custom-black font-family font-normal text-left text-base ' +
                                        'rounded-xl border border-gray-400 w-full h-12 p-4'}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="summary"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                            <div className={'react-css flex flex-col items-start justify-start gap-2 w-full mb-6'}>
                                <p className={'react-css text-custom-black font-family text-xs hover:text-current'}>
                                    {'Evaluare: '}
                                </p>
                                <textarea
                                    {...register('text', {
                                        maxLength: {
                                            value: 1000,
                                            message: "Te rogam sa introduci mai puntin de 1000 caractere."
                                        }
                                    })}
                                    placeholder="Scrie-ne parerea ta"
                                    rows={6}
                                    className={'react-css text-custom-black font-family font-normal text-left ' +
                                        'text-base rounded-xl border border-gray-400 w-full h-auto min-w-[150px] p-4'}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="text"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                        </div>

                        {/*Submit button*/}
                        <div className={'react-css flex items-center justify-center align-center gap-2 w-full mb-6'}>
                            <button type="submit"
                                    className={
                                        "rounded-xl bg-first-btn-bg hover:bg-first-btn-bg focus:bg-first-btn-bg " +
                                        "font-family font-bold text-sm text-center text-black focus:text-white hover:text-white " +
                                        "shadow-first-btn-shadow transform hover:translate-y-1 mt-2 w-80 py-3 px-4 " +
                                        "flex items-center justify-center"
                                    }>
                                {'Trimite evaluarea'}
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {isModalOpen && (
                <div className={'react-css fixed top-0 left-0 w-full h-full flex items-center justify-center z-10'}>
                    <div
                        className={'react-css bg-custom-bg p-6 rounded-xl shadow-custom-box-shadow w-96 p-6 absolute top-24'}>
                        <h2 className={'react-css text-base mb-4'}>{'Multumim!'}</h2>
                        <p>{'Evaluarea dv a fost trimisa!'}</p>
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className={'react-css mt-4 bg-main-orange text-black px-4 py-2 rounded hover:bg-main-orange'}
                            type="button"
                        >
                            Inchide
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
