import React, {createContext, useContext, ReactNode, useState} from 'react';
import {request} from '../../../Infrastructure';
import {useQuery, useMutation} from "@tanstack/react-query";
import {getCmsBlocks, subscribeToNewsletter} from '../../queries';

interface CmsBlock {
    identifier: string;
    title: string;
    content: string;
}

interface CmsBlocksData {
    items: CmsBlock[];
}

interface ThemeContextValue {
    cmsBlocks: CmsBlock[];
    loading: boolean;
    error: unknown;
    getCmsBlock: (identifier: string) => CmsBlock | null;
    setCmsBlocks: (blocks: CmsBlock[]) => void;
    updateCmsBlock: (identifier: string, newData: Partial<CmsBlock>) => void;
    subscribeNewsletter: (email: string) => void;
    newsletterStatus: {
        loading: boolean;
        error: string | null;
        success: boolean;
    };
}

const ThemeContextProvider = createContext<ThemeContextValue | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [cmsBlocksData, setCmsBlocksData] = useState<CmsBlocksData>({items: []});

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks'],
        queryFn: async () => {
            const response = await request(getCmsBlocks, {
                identifiers: [
                    "suport_clienti",
                    "informatii_generale",
                    "linkuri_utile",
                    "footer_tos",
                ]
            });

            if (response?.data?.cmsBlocks?.items) {
                setCmsBlocksData({
                    items: response.data.cmsBlocks.items
                });
            }

            return response;
        },
        refetchOnWindowFocus: false,
    });

    const newsletterMutation = useMutation({
        mutationFn: async (email: string) => {
            return request(subscribeToNewsletter, {email});
        }
    });

    const getCmsBlock = (identifier: string): CmsBlock | null => {
        try {
            return cmsBlocksData.items.find(block => block?.identifier === identifier) || null;
        } catch (error) {
            console.error('Error getting CMS block:', error);
            return null;
        }
    };

    const setCmsBlocks = (blocks: CmsBlock[]) => {
        setCmsBlocksData({items: blocks});
    };

    const updateCmsBlock = (identifier: string, newData: Partial<CmsBlock>) => {
        setCmsBlocksData(prevData => ({
            items: prevData.items.map(block =>
                block.identifier === identifier
                    ? {...block, ...newData}
                    : block
            )
        }));
    };

    const subscribeNewsletter = (email: string) => {
        newsletterMutation.mutate(email);
    };

    const contextValue: ThemeContextValue = {
        cmsBlocks: cmsBlocksData.items,
        loading: cmsBlockQuery.isLoading,
        error: cmsBlockQuery.error,
        getCmsBlock,
        setCmsBlocks,
        updateCmsBlock,
        subscribeNewsletter,
        newsletterStatus: {
            loading: newsletterMutation.isLoading,
            error: newsletterMutation.data?.errors?.[0]?.message || null,
            success: newsletterMutation.data?.data?.subscribeEmailToNewsletter?.status === "SUBSCRIBED"
        }
    };

    return (
        <ThemeContextProvider.Provider value={contextValue}>
            {children}
        </ThemeContextProvider.Provider>
    );
};

export const useThemeContextProvider = () => {
    const context = useContext(ThemeContextProvider);
    if (!context) {
        throw new Error('useThemeContextProvider must be used within a ThemeProvider');
    }
    return context;
};
