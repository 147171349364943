import React from "react";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {PageTitle} from "./ProductInfo/PageTitle";
import {SpecialPromo} from "./ProductInfo/SpecialPromo";
import {InfoReviews} from "./ProductInfo/InfoReviews";
import {StockStatus} from "./ProductInfo/StockStatus";
import {PriceInfo} from "./ProductInfo/PriceInfo";
import {AddToCart} from "./AddToCart/AddToCart";
import {useWindowSize} from "../../../../general";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductInfo: React.FC<ProductSku> = ({productSku}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <ProductAttributeProvider productSku={productSku}>
                <div>
                    <div className={'bg-cards-bg p-5 flex flex-col justify-start mb-3 xl:mb-5'}>
                        <PageTitle/>
                        <SpecialPromo/>
                        <InfoReviews/>
                        <p className={'react-css font-family text-sm xl:text-lg text-second-btn-bg mb-5 text-center md:text-start xl:text-start'}>
                            {'Cod produs: '}{productSku}
                        </p>
                        <StockStatus/>
                    </div>

                    <PriceInfo/>
                    <div className={'bg-cards-bg px-5 pt-3 xl:bg-inherit xl:px-0 xl:pt-0'}>
                        <div className={'flex flex-row items-center justify-start gap-3'}>
                            <AddToCart productSku={productSku}/>
                        </div>
                        <div
                            className={'flex flex-row items-center justify-center md:justify-start xl:justify-start pl-0 xl:pl-5'}>
                            <svg
                                className="w-5 h-5 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512">
                                <path
                                    d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96
                            96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3
                            32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512
                            114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416
                            160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96
                            0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                            </svg>
                            <span className={'text-custom-black font-family text-sm xl:text-base'}>
                            {'Cost transport: 30.00 Lei'}
                        </span>
                        </div>
                        <div
                            className={'flex flex-row items-start justify-center md:justify-start xl:justify-start pl-0 xl:pl-5 md:mb-5 xl:mb-5'}>
                            <svg
                                className="w-5 h-5 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512">
                                <path
                                    d="M97.3 507c-129.9-129.9-129.7-340.3 0-469.9 5.7-5.7 14.5-6.6 21.3-2.4l64.8
                                    40.5a17.2 17.2 0 0 1 6.8 21l-32.4 81a17.2 17.2 0 0 1 -17.7 10.7l-55.8-5.6c-21.1
                                    58.3-20.6 122.5 0 179.5l55.8-5.6a17.2 17.2 0 0 1 17.7 10.7l32.4 81a17.2
                                    17.2 0 0 1 -6.8 21l-64.8 40.5a17.2 17.2 0 0 1 -21.3-2.4zM247.1 95.5c11.8 20
                                    11.8 45 0 65.1-4 6.7-13.1 8-18.7 2.6l-6-5.7c-3.9-3.7-4.8-9.6-2.3-14.4a32.1
                                    32.1 0 0 0 0-29.9c-2.5-4.8-1.7-10.7 2.3-14.4l6-5.7c5.6-5.4 14.8-4.1 18.7
                                    2.6zm91.8-91.2c60.1 71.6 60.1 175.9 0 247.4-4.5 5.3-12.5 5.7-17.6 .9l-5.8-5.6c-4.6-4.4-5-11.5-.9-16.4
                                    49.7-59.5 49.6-145.9 0-205.4-4-4.9-3.6-12 .9-16.4l5.8-5.6c5-4.8 13.1-4.4 17.6 .9zm-46 44.9c36.1 46.3
                                    36.1 111.1 0 157.5-4.4 5.6-12.7 6.3-17.9 1.3l-5.8-5.6c-4.4-4.2-5-11.1-1.3-15.9 26.5-34.6 26.5-82.6
                                    0-117.1-3.7-4.8-3.1-11.7 1.3-15.9l5.8-5.6c5.2-4.9 13.5-4.3 17.9 1.3z"/>
                            </svg>
                            <div
                                className="flex flex-wrap items-center xl:gap-1 justify-center md:justify-start pb-3 pace-x-1 w-[250px] md:w-[100%] xl:w-[100%]">
                                <span className="text-custom-black font-family text-sm xl:text-base ">
                                    {'Comanda prin telefon: '}
                                    <a
                                        className="text-custom-black font-family text-sm xl:text-base"
                                        href="tel:0377100000">0377 100 000
                                    </a>
                                </span>
                                <span className="text-custom-black font-family text-sm xl:text-base">
                                   {'intre orele 08:00 - 17.00 (L-V)'}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </ProductAttributeProvider>
        </QueryClientProvider>
    );
}
