import React from 'react';
import {useThemeContextProvider} from '../../../state';

function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Blocks = ({identifier}) => {
    const {getCmsBlock} = useThemeContextProvider();
    const blockData = getCmsBlock(identifier);

    if (!blockData) return null;

    return (
        <div className="blocks flex justify-center flex-row items-start mb-5">
            <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData.content)}}></div>
        </div>
    );
};
