import React from 'react';

export const FooterLinks = () => {
    const basePath = '/static/frontend/Eadesignro/theme/ro_RO/images/';

    return (
        <div className={'react-css flex flex-col justify-center items-center w-full md:w-auto'}>
            <p className={'text-left text-white font-family text-xs xl:text-sm uppercase'}>{'Soluționare alternativă a litigiilor'}</p>
            <div className={'flex !flex-row md:!flex-col gap-5 md:gap-3'}>
                <a className="anpc-sal" rel="nofollow"
                   href="https://anpc.ro/ce-este-sal/"
                   title="Solutionarea alternativa a litigiilor"
                   target="_blank">
                    <img className={'h-[40px]'} src={`${basePath}SAL.svg`}
                         alt="Solutionarea alternativa a litigiilor"/>
                </a>
                <a className="anpc-sol" rel="nofollow"
                   href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                   title="Solutionarea online a litigiilor"
                   target="_blank">
                    <img className={'h-[40px]'} src={`${basePath}SOL.svg`}
                         alt="Solutionarea online a litigiilor"/>
                </a>
            </div>
        </div>
    );
};
