import React from 'react';

export const FormatPrice = ({ price, currency = 'Lei', decimalFontSize }) => {
    if (price === null || price === undefined) return null;

    const formatPrice = (price) => {
        let parts = price.toFixed(2).split(".");
        return (
            <span>
                {parts[0]}
                <span className={decimalFontSize}>.{parts[1]}</span> {currency}
            </span>
        );
    };

    return (
        <span className="formatted-price">
            {formatPrice(price)}
        </span>
    );
};
