import React from 'react';
import {useProductAttributes} from "../../../state";

export const SafetyStihl = () => {
    const {categories} = useProductAttributes() || {};
    const hasCategory = categories?.some(category => category.id === 197);

    if (!hasCategory) {
        return null;
    }

    return (
        <div
            className='react-css flex flex-col p-3 xl:p-5 items-center gap-3'>
            <div className='flex flex-row gap-2 items-center'>
                <svg className='fill-current text-black w-4 h-4 md:w-6 md:h-6'
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3
                        0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7
                        24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                </svg>
                <span className='text-start text-black font-family text-sm xl:text-base font-bold leading-7'>
                        MASURI DE SIGURANTA
                </span>
            </div>
            <div>
                <p className='text-center text-black font-family text-sm xl:text-base leading-5 xl:leading-7 font-normal'>
                    Pentru a cunoaste pericolele utilizarii echipamentelor, va recomandam
                    sa cititi cu atentie instructiunile de folosire.
                    <br/>
                    Daca informatiile nu va sunt clare, puteti verifica si canalul de
                    <a className='ml-1 font-normal underline text-black font-family text-sm xl:text-base leading-5 xl:leading-7'
                       rel="nofollow"
                       href="https://www.youtube.com/user/STIHLRomania"
                       target="_blank"
                       title="YouTube STIHL Romania"
                    >
                        YouTube STIHL Romania
                    </a>
                </p>
            </div>
        </div>
    );
}
