import React, {useMemo} from "react";
import {useProductAttributes} from "../../../state";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const SpecialPromo = () => {
    const {productAttributes} = useProductAttributes() || {};
    const giftContent = productAttributes?.special_text_promo;

    const decodedGiftContent = useMemo(() => {
        return giftContent ? decodeHtml(giftContent) : null;
    }, [giftContent]);

    return (
        <>
            {giftContent &&
            new Date(productAttributes?.text_to_date) >= new Date() &&
            new Date(productAttributes?.text_from_date) <= new Date() && (
                <div className={'flex flex-row justify-center md:justify-start xl:justify-start items-center bg-cards-bg mb-3 xl:mb-5'}>
                    <svg
                        className={`w-5 h-5 xl:w-9 xl:h-9 mr-5`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path
                            d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3
                    20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0
                    32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9
                    0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336
                    0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32
                    288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/>
                    </svg>
                    <div className={'flex justify-start font-family text-sm xl:text-xl text-custom-red'}
                         dangerouslySetInnerHTML={{__html: decodedGiftContent}}
                    />
                </div>
            )}
        </>
    );
}
