import React, {useEffect, useMemo} from 'react';
import { useProductAttributes } from '../../../state';
import {DetailsSkeleton} from "../Skeleton/DetailsSkeleton";
import {Brands} from "../ProductInfo/Brands";
import {SafetyStihl} from "../ProductInfo/SafetyStihl";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Description = () => {
    const { description, productInfoConfig, loading } = useProductAttributes() || {};

    const decodedDescription = useMemo(() => {
        return description ? decodeHtml(description) : null;
    }, [description]);

    if (loading) {
        return <DetailsSkeleton/>;
    }

    if (!decodedDescription) {
        return null;
    }

    return (
        <div className={'react-css bg-white xl:mb-5 mb-2 mt-5'}
             id={'productDescription'}>
            <div className={'bg-cards-bg font-custom-black'}>
                <h2
                    className={'react-css font-family font-semibold leading-[50px] font-custom-black text-base pl-10 pr-10'}>
                    {'Descriere'}
                </h2>
            </div>
            <div className={'react-css overflow-auto max-h-[500px] p-3 border-2 border-solid border-cards-bg flex flex-col items-start'}>
                <div
                    className={'flex flex-row items-center bg-cards-bg border-solid border-y-4 border-white xl:border-0 w-full md:w-1/2'}>
                    <div className="w-1/3">
                        <Brands logoUrl={productInfoConfig.attributes?.logoUrl}/>
                    </div>
                    <div className="w-2/3">
                        <SafetyStihl/>
                    </div>
                </div>
                <div
                    className={'font-family text-sm xl:text-base w-full'}
                    dangerouslySetInnerHTML={{__html: decodedDescription}}
                />
            </div>
        </div>
    );
}
