import React from 'react';
import {useProductAttributes} from '../../../state';
import {useWindowSize, CardItem} from "../../../../../general";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {Navigation} from 'swiper/modules';
import {SliderSkeleton} from "../Skeleton/SliderSkeleton";

export const AutoRelated = () => {
    const {autoRelatedProducts, loading} = useProductAttributes() || {};
    const {isMobile, isTablet} = useWindowSize();
    const itemsToShow = isMobile ? 2 : isTablet ? 4 : 5;
    const gapBetween = isMobile ? 0 : 0;

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const availableProducts = autoRelatedProducts.filter(product => {
        const isInStock = findCustomAttributeValue(product?.custom_attributes, 'is_in_stock');
        return isInStock !== "Out of Stock";
    });

    if (loading) {
        return <SliderSkeleton/>;
    }

    if (!availableProducts.length) return null;

    let productsToShow;
    if (isMobile) {
        productsToShow = availableProducts.slice(0, 4);
    } else if (isTablet) {
        productsToShow = availableProducts.slice(0, 4);
    } else {
        productsToShow = availableProducts.slice(0, 5);
    }

    let marginLeft;
    if (isMobile) {
        marginLeft = productsToShow.length > 2 ? 'ml-[16%]' : 'ml-[0]';
    } else if (isTablet) {
        marginLeft = productsToShow.length > 5 ? 'ml-[8%]' : 'ml-[0]';
    } else {
        marginLeft = productsToShow.length > 5 ? 'ml-[5%]' : 'ml-[0]';
    }

    return (
        <>
            {availableProducts ? (
                <div className={'relative react-css mt-5'}>
                    <h2 className={'absolute font-family font-normal text-sm xl:text-lg leading-[40px] xl:leading-[50px] ' +
                        ' font-custom-black z-[2] pl-5 ' + marginLeft}>
                        {'Produse similare'}
                    </h2>
                    <Swiper navigation={true}
                            modules={[Navigation]}
                            slidesPerView={itemsToShow}
                            spaceBetween={gapBetween}
                            freeMode={true}
                            className={'react-slider'}
                    >
                        {productsToShow ?.map((product) => (
                            <SwiperSlide key={product.id}
                                         className={'bg-cards-bg px-1 py-2 !w-[49.4%] md:!w-[19.9%] tablet:!w-[24.8%] xl:!w-[19.9%]'}
                            >
                                <CardItem product={product}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div></div>
            )}
        </>

    );
};
