import React from 'react';

export const FooterCopyright = () => {

    return (
        <p className={'text-left text-white font-family text-xs xl:text-sm'}>
            {'©'} {new Date().getFullYear()} - Global Tech SRL
        </p>
    );
};
