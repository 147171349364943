import React, {useState} from 'react';
import {useCheckoutState} from "../../../../Checkout";
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";
import {Translate} from "../../../../../general//assets/Translate";
import {useProductAttributes} from "../../../state";
import {AddToCartSkeleton} from "../Skeleton/AddToCartSkeleton";
import {useWindowSize} from "../../../../../general";
import {AddToFavoritesButton} from "./AddToFavoritesButton";

export const AddToCartButton = () => {
    const {isTablet} = useWindowSize();

    const {handleAddToCart, addingToCart, errorMessage} = useCheckoutState();
    const [qty, setQty] = useState(1);

    const {
        loading,
        productAttributes,
        customAttributes,
        productSku,
        categories
    } = useProductAttributes() || {};

    if (loading) {
        return <AddToCartSkeleton/>;
    }

    const product_type_id = productAttributes?.type_id;
    const isInStock = customAttributes?.find(attr => attr.attribute_code === 'is_in_stock')?.value === 'In Stock';
    const showAddToCart = customAttributes?.find(attr => attr.attribute_code === 'cu_fara_pret')?.value == 'Da';
    const hasCategory = categories?.some(category => category.id === 470);

    const incrementQty = () => {
        setQty(qty + 1);
    };

    const decrementQty = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };

    return (
        <>
            {product_type_id != 'bundle' ? (
                <div className={'mt-0 mb-3 xl:mt-5 xl:mb-5 flex justify-left flex-col'}>
                    <div className={'react-addToCart flex justify-center xl:justify-start w-full'}>
                        {!isInStock || !showAddToCart ? (
                            <>
                                {hasCategory ? (
                                    <p className={'bg-cards-bg w-full h-auto text-center text-lg xl:text-2xl border-0 p-2 font-montserrat font-bold'}>
                                        {'Produsul nu mai este disponibil'}
                                    </p>
                                ) : (
                                    <div className={'flex justify-center flex-col w-full'}>
                                        <p className={'bg-cards-bg w-full h-auto text-center text-lg xl:text-2xl border-0 p-2 font-montserrat font-bold'}>
                                            {!showAddToCart ? 'Produsul nu se mai comercializeaza' : 'STOC EPUIZAT'}
                                        </p>
                                        {showAddToCart && (
                                            <p className={'bg-cards-bg w-full h-auto text-center text-custom-red text-lg'}>
                                                {'Acest produs este indisponibil în stocurile noastre. Pentru a verifica ' +
                                                    'disponibilitatea la producător, te rugăm să ne '}
                                                <a
                                                    href="https://www.tools.store.ro/contact" title="Contactează-ne"
                                                    className={'underline font-bold text-custom-red hover:text-custom-red focus:text-custom-red ' +
                                                        'active:text-custom-red visited:text-custom-red '}
                                                >
                                                    {'contactezi'}
                                                </a>
                                            </p>)}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={'flex flex-row gap-4 w-full items-center'}>
                                <div className={'flex flex-row border-solid border-2 border-border-color '}>
                                    <button
                                        className={'bg-cards-bg w-[35px] h-[42px] xl:w-[45px] xl:h-[42px] ' +
                                            'text-center text-2xl border-0 font-montserrat font-bold'}
                                        onClick={decrementQty}
                                    >-
                                    </button>
                                    <input
                                        className={'qty-input w-[45px] h-[42px] border-x-2 ' +
                                            'text-center text-2xl font-montserrat font-bold'}
                                        name={'qty'}
                                        value={qty}
                                        onChange={(e) => setQty(parseInt(e.target.value) || 1)}
                                    />
                                    <button
                                        className={'bg-cards-bg w-[35px] h-[42px] xl:w-[45px] xl:h-[42px] ' +
                                            'text-center text-2xl border-0 font-montserrat font-bold'}
                                        onClick={incrementQty}
                                    >+
                                    </button>
                                </div>
                                <button
                                    className={'h-[45px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                        'text-black font-family font-bold p-4 w-4/5 xl:w-3/4 ' +
                                        'hover:bg-main-orange transition-all duration-300 ease-in-out ' +
                                        'flex justify-center items-center border-0 hover:border-0'}
                                    onClick={() => handleAddToCart({sku: productSku, quantity: qty})}
                                >
                                    <>
                                        {isTablet ? (
                                            <>
                                                {productSku === addingToCart ? (
                                                    <LoadingIcon/>
                                                ) : (
                                                    <svg className={''}
                                                         xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                                         viewBox="0 0 576 512">
                                                        <path fill="#000000"
                                                              d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                    </svg>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {productSku === addingToCart ? (
                                                    <LoadingIcon/>
                                                ) : (
                                                    <>
                                                        <span
                                                            className={'fold:hidden font-family fold:text text-base tablet:text-sm xl:text-lg font-black'}>{'Adauga in cos'}
                                                        </span>
                                                        <svg className={'ml-1 xl:ml-5'}
                                                             xmlns="http://www.w3.org/2000/svg" height="24" width="26"
                                                             viewBox="0 0 576 512">
                                                            <path fill="#000000"
                                                                  d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                        </svg>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                </button>
                                <AddToFavoritesButton productId={productSku}/>
                            </div>
                        )}
                    </div>
                    {errorMessage && (
                        <div className={'text-red-500 text-sm mt-3'}>
                            <Translate message={errorMessage}/>
                        </div>
                    )}
                </div>
            ) : (
                <div></div>
            )
            }
        </>
    );
};
