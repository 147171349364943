import React from "react";
import {Blocks} from "./assets/Blocks";
import {ThemeProvider} from "../../state";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {FooterCopyright} from "./assets/FooterCopyright";
import {FooterLinks} from "./assets/FooterLinks";
import {FooterInfo} from "./assets/FooterInfo";
import {NewsLetter} from "./NewsLetter";
import {Utils} from "./assets/Utils";

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 30 * 60 * 1000, // 30 minutes
            retry: 2,
            retryDelay: 1000,
            refetchOnWindowFocus: false,
        },
    },
});

export const FooterContainer = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <Utils/>
                <div className={'w-full bg-[#e8e8e8] flex items-center justify-center'}>
                    <NewsLetter/>
                </div>
                <div className={'flex flex-col gap-10 w-full bg-black items-center justify-center pt-5 pb-10 px-5'}>
                    <div className={'flex flex-row flex-wrap md:flex-nowrap items-start justify-between text-white w-full xl:w-[1280px] bg-black text-sm'}>
                        <Blocks identifier={'suport_clienti'}/>
                        <Blocks identifier={'informatii_generale'}/>
                        <Blocks identifier={'linkuri_utile'}/>
                        <Blocks identifier={'footer_tos'}/>
                        <FooterLinks/>
                    </div>
                    <div className={'w-full flex flex-col items-start justify-start md:flex-row-reverse justify-between md:items-end w-full xl:w-[1280px]'}>
                        <FooterInfo/>
                        <FooterCopyright/>
                    </div>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    )
}
