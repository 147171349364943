import React from 'react';
import {useProductAttributes} from "../../../state";

export const Brands = ({logoUrl}) => {
    const {categories} = useProductAttributes() || {};
    const hasCategory = categories?.some(category => category.id === 197);
    const targetCategory = categories?.find(category => category.id === 197);
    const manufacturerUrl = targetCategory?.url_path
        ? targetCategory.url_path.replace('default-category/', '') + '.html'
        : '#';

    if (!hasCategory) {
        return null;
    }

    return (
        <div className="react-css p-3 xl:p-6">
            <a href={manufacturerUrl}
               className="react-css flex flex-col justify-center text-black gap-2 text-black font-family text-sm xl:text-base leading-5 xl:leading-7
                hover:text-black focus:text-black active:text-black" >
                {logoUrl && (
                    <img src={logoUrl} alt="Brand Logo" className="mx-auto w-52 text-sm"/>
                )}
                <p className="react-css text-center text-black font-family text-sm xl:text-base leading-5 xl:leading-7">
                    Vezi toate produsele STIHL
                </p>
            </a>
        </div>
    );
}
