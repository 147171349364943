import React from 'react';

interface ProgressBarProps {
    currentTotal: number;
    promotionLevels: number[];
}

export const ProgressBar: React.FC<ProgressBarProps> = (
    {
        currentTotal,
        promotionLevels
    }
) => {
    let nextLevel = 0;
    let prevLevel = 0;
    let levelReached = 0;
    let maxLevelValue = -Infinity;

    let sortedLevels = promotionLevels.sort(function (a, b) {
        return a - b
    });
    for (let i = 0; i < promotionLevels.length; i++) {
        if (promotionLevels[i] > maxLevelValue) {
            maxLevelValue = promotionLevels[i];
        }
    }

    nextLevel = maxLevelValue;

    for (let i = 0; i < sortedLevels.length; i++) {
        if (currentTotal >= sortedLevels[i]) {
            levelReached = i + 1;
            prevLevel = sortedLevels[i];
        } else {
            nextLevel = sortedLevels[i];
            break;
        }
    }

    let percentage = ((currentTotal - prevLevel) / (nextLevel - prevLevel)) * 100;
    let difference = (nextLevel - currentTotal).toFixed(2);
    let maxLevel = promotionLevels.length;

    return (
        <div className={'flex justify-between p-3 xl:p-0 flex-col xl:flex-row'}>
            <div className={'bg-yellow-500 p-4 xl:p-8 xl:rounded-l-xl rounded-t-xl xl:rounded-r-none w-full xl:w-1/2'}>
                <div className={'flex mb-2 items-center justify-between flex gap-4 flex-row '}>
                    <div>
                      <span
                          className={'text-base font-semibold inline-block py-1 px-2 rounded-full text-white bg-red-500 font-family'}>
                        {'Valoare cos:'} {' '} {currentTotal} {'Lei'}
                      </span>

                    </div>
                </div>
                <div className={'overflow-hidden h-4 mb-4 mt-4 text-sm flex rounded bg-black'}>
                    <div style={{width: `${Math.min(percentage, 100)}%`}}
                         className={'shadow-none flex flex-col text-center whitespace-nowrap font-family text-white justify-center bg-red-500'}>
                    </div>
                </div>
            </div>
            <div className={'bg-black p-4 pr-10 rounded-b-xl xl:rounded-r-xl xl:rounded-l-none flex flex-col items-end gap-3 w-full xl:w-1/2'}>
                <p className={'text-base font-bold text-red-600 text-right font-family'}>
                    NIVEL de discount: {' '}
                    <span className={'text-4xl font-bold text-red-600 font-family'}>
                        {levelReached}
                    </span>
                </p>
                {levelReached < maxLevel && (
                    <p className={'text-base font-bold text-white mt-2 text-right font-family'}>
                        {'Mai ai de adaugat doar '}
                        <span className={'text-xl xl:text-2xl font-bold text-red-600 font-family'}>
                            {difference} {'Lei'}
                        </span>
                        {' in cos pentru a ajunge la urmatorul nivel de discount'}
                    </p>
                )}
            </div>
        </div>
    );
};
