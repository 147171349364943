import React, {useState, useEffect, useRef} from 'react';
import {useProductAttributes} from '../../../state';
import {DetailsSkeleton} from "../Skeleton/DetailsSkeleton";
import {AttachmentsComponent} from "../ProductInfo/AttachmentsComponent";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const AdditionalInfo = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const {appConfig, loading} = useProductAttributes() || {};

    if (loading) {
        return <DetailsSkeleton/>;
    }

    if (!appConfig) {
        return null;
    }

    return (
        <div className={'react-css bg-white xl:mb-5 mb-2'}
             id={'productAdditional'}>
            <div className={'bg-cards-bg font-custom-black'}>
                <h2
                    className={'font-family font-semibold leading-[50px] font-custom-black text-base pl-10 pr-10'}>
                    {'Specificatii tehnice'}
                </h2>
            </div>
            <div
                className={'react-css overflow-auto max-h-[500px] p-3 border-2 border-solid border-cards-bg'}
                ref={contentRef}>

                <table className={'react-css table-auto border mb-8'}>
                    <thead>
                    </thead>
                    <tbody>
                    {Object.entries(appConfig.attributes).map(([key, attr]) => (
                        <tr className={'react-css border-b'} key={key}>
                            <td className={'react-css align-middle border font-family text-sm xl:text-base text-custom-black font-semibold leading-5'}>{attr.label}</td>
                            <td className={'react-css align-middle border font-family text-sm xl:text-base text-custom-black leading-5'}
                                dangerouslySetInnerHTML={{__html: decodeHtml(attr.value)}}
                            ></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <AttachmentsComponent/>
            </div>
            <div id="hexamarvel_attachments_react_app" className=""></div>
        </div>
    );
}
