import React from 'react';
import {NewsLetterForm} from './assets/NewsLetterForm';

export const NewsLetter: React.FC = () => {

    return (
        <div className={'w-full md:w-[1280px] bg-[#e8e8e8] flex md:!flex-row !flex-col items-center justify-around p-5'}>
            <div className={'flex flex-col items-center xl:items-start justify-center w-full xl:w-2/5'}>
                <p className={'text-center md:text-left font-family text-xl font-bold'}>{'Abonati-va la newsletter'}</p>
                <p className={'text-center md:text-left font-family text-base'}>{'Si va vom tine la curent cu cele mai noi promotii.'}</p>
            </div>
            <div className={'flex flex-col items-center justify-center w-full md:w-3/5 mt-3 md:mt-auto'}>
                <NewsLetterForm/>
                <p className={'text-left font-family text-xs mt-2'}>
                    {'Inscriindu-te esti de acord sa primesti oferte promotionale pe ' +
                        'emailul furnizat, in concordanta cu politica de Prelucrarea ' +
                        'datelor personale. Te vei putea dezabona in orice moment.'}
                </p>
            </div>
        </div>

    );
};
