import React, {useState} from 'react';
import {useCheckoutState} from "../../../Checkout";
import {FormatPrice, LoadingIcon, useWindowSize} from '../../../../general';

export const PromoCardItem = ({product, discount, discountLabel}) => {
    const {isMobile, isTablet} = useWindowSize();
    const {handleAddToCart, addingToCart} = useCheckoutState();
    const [qty, setQty] = useState(1);

    if (!product) return null;

    const productUrl = `${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`;

    const formatPrice = (price) => {
        let parts = price.toFixed(2).split(".");
        let formattedPrice = `${parts[0]}.${parts[1]}`;
        formattedPrice = `${formattedPrice} Lei`;

        return formattedPrice;
    };

    const regularPriceValue = product?.price_range?.minimum_price?.regular_price.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const discountedPrice = regularPriceValue * discount;
    const discountedPriceFormatted = formatPrice(discountedPrice);

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const truncateCombinedStrings = (str1, str2, maxLength) => {
        str1 = str1 || "";
        str2 = str2 || "";

        if (str1 && str2) {
            str1 += " ";
        }

        const totalLength = str1.length + str2.length;

        if (totalLength <= maxLength) return [str1, str2];
        let availableLengthForStr2 = maxLength - str1.length;

        if (availableLengthForStr2 <= 3) return [str1, ""];

        const truncatedStr2 = str2.slice(0, availableLengthForStr2 - 3) + '...';
        return [str1, truncatedStr2];
    };

    const productName = product?.name;
    let cutProductName = '';

    if (productName && productName.length > 70) {
        cutProductName = productName.substring(0, 67) + '...';
    } else {
        cutProductName = productName;
    }

    const h2ProductName = findCustomAttributeValue(product?.custom_attributes, 'h2_product_name');

    const [adjustedProductName, adjustedH2ProductName] = truncateCombinedStrings(cutProductName, h2ProductName, 75);

    const showAddToCart = findCustomAttributeValue(product?.custom_attributes, 'cu_fara_pret');
    const isInStock = findCustomAttributeValue(product?.custom_attributes, 'is_in_stock');
    const hasCategory = product?.categories?.some(category => category.id === 470); // 470 is the category ID for "resigilate"

    return (
        <div
            className={'relative bg-white px-2 py-2.5 justify-between items-center gap-1 ' +
                ' border-0 xl:hover:border border-custom-black grid grid-rows-2 w-auto h-auto xl:h-[400px]'}>
            {(isInStock === "Out of Stock" || showAddToCart === "Nu") && (
                    <div
                        className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10'}>
                    </div>
                )
            }
            <p className={'absolute absolute left-0 top-0 bg-gradient-to-r from-orange-400 to-red-700 text-slate-100 m-1 p-1 ' +
                'font-medium text-center font-family text-xs xl:text-base leading-3 xl:leading-5'}>
                {discountLabel}
            </p>
            <a className={'row-span-1 h-[150px] xl:h-[200px] flex justify-center items-center '}
               href={productUrl}
               title={product?.name}
            >
                <img src={product?.customProductImageUrl?.url} alt={product?.small_image?.label}
                     className={'w-5/6 xl:w-4/5 object-contain object-center max-h-[190px] text-xs'} loading="lazy"/>
            </a>

            <div className={'row-span-1 grid grid-row-3 gap-2 h-[135px] xl:h-[185px] w-auto'}>
                <a
                    className={'h-[50px] xl:h-[60px] text-black over:no-underline active:text-black overflow-hidden '}
                    href={productUrl}
                    title={product?.name}
                >
                    <p className={'react-css leading-[14px] xl:leading-5 text-center text-sm xl:text-base font-medium font-family text-custom-black ' +
                        'h-[42px] xl:h-[60px] overflow-hidden'}>
                        {adjustedProductName}
                        <span
                            className={'react-css leading-[14px] xl:leading-5 text-center text-sm xl:text-base font-medium font-family text-custom-black'}>
                            {adjustedH2ProductName}
                        </span>
                    </p>
                </a>
                <div className={'h-[40px] xl:h-[50px] flex flex-col justify-end items-center'}>
                    <div className={'react-css flex flex-col items-center gap-0 xl:gap-1'}>
                        <p className={'react-css text-xs xl:text-base font-medium line-through font-family'}>
                            {regularPriceFormatted}
                        </p>
                        <p className={'react-css text-base xl:text-[22px] font-bold text-custom-red font-family'}>
                            <FormatPrice price={discountedPrice} decimalFontSize={'text-sm xl:text-base'}/>
                            <span
                                className={'react-css text-[11px] xl:text-base font-medium text-black ml-2 font-family'}>{'cu TVA'}
                            </span>
                        </p>
                    </div>
                </div>

                <div className={'flex justify-center'}>
                    {product?.type_id != 'simple' ? (
                        <button
                            className={'h-[35px] xl:h-[45px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                'text-black text-xs xl:text-base font-family font-bold w-4/5 xl:w-full ' +
                                'hover:bg-main-orange transition-all duration-300 ease-in-out border-0 hover:border-0'}
                            onClick={() => window.location.href = productUrl}
                        >
                            <span className={'uppercase text-lg font-black'}>{'Vezi detalii'}</span>
                        </button>
                    ) : (
                        <>
                            {showAddToCart != 'Da' || isInStock != 'In Stock' ? (
                                <>
                                    {hasCategory ? (
                                        <p className={'bg-cards-bg w-full h-auto text-center text-sm xl:text-2xl border-0 p-2 font-montserrat font-bold'}>
                                            {'Produsul nu mai este disponibil'}
                                        </p>
                                    ) : (
                                        <p className={'bg-cards-bg w-full h-auto text-center text-sm border-0 font-montserrat font-bold'}>
                                            {showAddToCart == 'Nu' ? 'Produsul nu se mai comercializeaza' : 'La comanda'}
                                        </p>
                                    )}
                                </>
                            ) : (
                                <button
                                    className={'h-[35px] xl:h-[45px] bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                                        'text-black text-base font-family font-bold w-4/5 xl:w-full ' +
                                        'hover:bg-main-orange transition-all duration-300 ease-in-out ' +
                                        'flex justify-center items-center border-0 hover:border-0'}
                                    onClick={() => handleAddToCart({sku: product?.sku, quantity: qty ? qty : 1})}
                                >
                                    {isMobile || isTablet ? (
                                        <>
                                            {product?.sku === addingToCart ? (
                                                <LoadingIcon/>
                                            ) : (
                                                <svg className={''}
                                                     xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                                     viewBox="0 0 576 512">
                                                    <path fill="#000000"
                                                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                </svg>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {product?.sku === addingToCart ? (
                                                <LoadingIcon/>
                                            ) : (
                                                <>
                                                <span
                                                    className={'font-family text-lg font-black'}>{'Adauga in cos'}</span>
                                                    <svg className={'ml-5'}
                                                         xmlns="http://www.w3.org/2000/svg" height="24" width="26"
                                                         viewBox="0 0 576 512">
                                                        <path fill="#000000"
                                                              d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                                    </svg>
                                                </>
                                            )}
                                        </>
                                    )}
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
