import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const DetailsSkeleton = () => {

    return (
        <div
            className={'h-[550px] flex flex-col items-start justify-center xl:justify-start mb-3 mt-3 xl:mb-5 gap-3 bg-cards-bg'}>

            <div className={'h-[50px] bg-cards-bg w-full'}>
                <Skeleton width={'100%'} height={40} className={'w-full p-2'}/>
            </div>
            <div className={'flex flex-row items-start justify-center xl:justify-start mb-3 mt-3 w-full'}>
                <div className={'w-full h-[500px] bg-white mr-1 ml-1  '}>
                    <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                </div>
            </div>
        </div>
    );
}
