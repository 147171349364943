import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {
    getProductDescription,
    getProductRelated,
    getProductAttributes,
    getAutoRelatedSkus,
    getAutoRelatedProducts,
    getCustomerWishlistId,
    addProductsToWishlist,
} from '../../queries';
import {useMutation, useQuery} from "@tanstack/react-query";

interface ProductAttributes {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    categories?: {
        id: number;
        name: string;
        url_path: string;
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    special_text_promo?: string;
    text_from_date?: string;
    text_to_date?: string;
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    price_range?: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
        discount?: {
            amount_off: { value: number; currency: string };
            percent_off: { value: number; currency: string }
        }
    };
    reviews?: {
        items?: {
            summary: string;
            text: string;
            nickname: string;
            created_at: string;
            average_rating: number;
            ratings_breakdown: {
                name: string;
                value: string;
            }[];
        }[];
    };
}

interface ProductDescription {
    id: string;
    sku: string;
    name: string;
    description?: {
        html: string;
    };
    short_description?: {
        html
    };
}

interface ProductRelated {
    upsell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
        special_text_promo?: string;
        text_from_date?: string;
        text_to_date?: string;
    }[];
    crosssell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        customWebpUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
        special_text_promo?: string;
        text_from_date?: string;
        text_to_date?: string;
    }[];
    related_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
        special_text_promo?: string;
        text_from_date?: string;
        text_to_date?: string;
    }[];
}

interface AutoRelatedSkus {
    id: string;
    sku: string;
    name: string;
}

interface AutoRelatedProducts {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    special_text_promo?: string;
    text_from_date?: string;
    text_to_date?: string;
}

declare global {
    interface Window {
        appConfig: {
            attributes: { [key: string]: AttributeType };
        };
        attachmentConfig: {
            attributes: {
                fileUrl: string;
                icon: string | null;
                name: string;
            }[];
        };
        productInfoConfig: {
            attributes: {
                logoUrl: string;
            };
        };
    }
}

type AttributeType = {
    label: string;
    value: string;
    code: string;
};

type ConfigType = {
    attributes: { [key: string]: AttributeType };
};

type ConfigManufacturer = {
    attributes: {
        logoUrl: string;
    };
};

type ConfigAttachments = {
    attributes: {
        fileUrl: string;
        icon: string | null;
        name: string;
    }[];
};

interface AddToWishlistResponse {
    data: {
        addProductsToWishlist: {
            wishlist: {
                id: string;
                items_count: number;
            };
            user_errors: Array<any>;
        };
    };
}

type WishlistData = AddToWishlistResponse;

interface ProductAttributeContextValue {
    productSku: string;
    productDescription: ProductDescription | null;
    productRelated: ProductRelated | null;
    productAttributes: ProductAttributes | null;
    loading: boolean;
    error: any;
    appConfig: ConfigType;
    configAttachments: ConfigAttachments;
    productInfoConfig: ConfigManufacturer;
    upsellProducts: any[];
    relatedProducts: any[];
    crossSellProducts: any[];
    autoRelatedProducts: any[];
    productName: string;
    description: string;
    customAttributes: {
        attribute_code: string;
        value: string;
    }[] | null;
    categories: {
        id: number;
        name: string;
        url_path: string;
    }[] | null;
    addToWishlist: (productSku: string) => void;
}

const ProductAttributeContext = createContext<ProductAttributeContextValue | undefined>(undefined);

export const ProductAttributeProvider: React.FC<{
    children: ReactNode,
    productSku: string,
}> = (
    {
        children,
        productSku,
    }
) => {
    const [productDescription, setProductDescription] = useState<ProductDescription | null>(null);
    const [productRelated, setProductRelated] = useState<ProductRelated | null>(null);
    const [productAttributes, setProductAttributes] = useState<ProductAttributes | null>(null);
    const [autoRelatedSkus, setAutoRelatedSkus] = useState<AutoRelatedSkus[] | []>([]);
    const [autoRelatedProducts, setAutoRelatedProducts] = useState<AutoRelatedProducts[] | []>([]);
    const [appConfig, setAppConfig] = useState<ConfigType>({attributes: {}});
    const [productInfoConfig, setProductInfoConfig] = useState<ConfigManufacturer>({ attributes: { logoUrl: '' } });
    const [configAttachments, setConfigAttachments] = useState<ConfigAttachments>({ attributes: [] });
    const [wishlistData, setWishlistData] = useState<WishlistData | null>(null);
    const [wishlistId, setWishlistId] = useState<string | null>(null);

    const upsellProducts = productRelated?.upsell_products || [];
    const relatedProducts = productRelated?.related_products || [];
    const crossSellProducts = productRelated?.crosssell_products || [];
    const productName = productAttributes?.name || null;
    const description = productDescription?.description.html || null;
    const customAttributes = productAttributes?.custom_attributes || null;
    const categories = productAttributes?.categories || null;

    const productDetailsQuery = useQuery({
        queryKey: ['productDescription'],
        queryFn: async () => {
            return request(getProductDescription, {sku: productSku});
        },
        enabled: true,
        // staleTime: 300000,
        refetchOnWindowFocus: false,
    });

    const productRelatedQuery = useQuery({
        queryKey: ['productRelated'],
        queryFn: async () => {
            return request(getProductRelated, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes'],
        queryFn: async () => {
            return request(getProductAttributes, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const autoRelatedSkusQuery = useQuery({
        queryKey: ['autoRelatedSkus', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(getAutoRelatedSkus, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    const autoRelatedProductsQuery = useQuery({
        queryKey: ['autoRelatedProducts', autoRelatedSkus],
        queryFn: async () => {
            const variables = {skus: autoRelatedSkus};
            return request(getAutoRelatedProducts, variables);
        },
        enabled: autoRelatedSkus?.length > 0,
        refetchOnWindowFocus: false,
    });

    const customerWishlistQuery = useQuery({
        queryKey: ['customerWishlist'],
        queryFn: async () => {
            return request(getCustomerWishlistId);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const addToWishlistMutation = useMutation<AddToWishlistResponse, Error, string>({
        mutationFn: async (sku: string) => {
            if (!wishlistId) {
                window.location.href = '/customer/account/login/';
                throw new Error("User not authenticated");
            }
            return request(addProductsToWishlist, {
                wishlistId,
                wishlistItems: [{ sku, quantity: 1 }]
            });
        },
        onSuccess: (data) => {
            window.location.href = '/wishlist/';
            setWishlistData(data);
        },
        onError: (error: Error) => {
            if (error.message !== "User not authenticated") {
                console.error('Error adding product to wishlist:', error);
            }
        },
    });

    const addToWishlist = (productSku: string) => {
        addToWishlistMutation.mutate(productSku);
    };

    useEffect(() => {
        if (productDetailsQuery?.data) {
            setProductDescription(productDetailsQuery?.data?.data?.products?.items[0]);
        }

        if (productAttributesQuery?.data) {
            setProductAttributes(productAttributesQuery?.data?.data?.products?.items[0]);
        }

        if (productRelatedQuery?.data) {
            setProductRelated(productRelatedQuery?.data?.data?.products?.items[0]);
        }

        if (autoRelatedSkusQuery?.data) {
            const skus = autoRelatedSkusQuery?.data?.data?.getAutoRelatedSkus.map(product => product.sku);
            setAutoRelatedSkus(skus);
        }

        if (autoRelatedProductsQuery?.data) {
            setAutoRelatedProducts(autoRelatedProductsQuery?.data?.data?.products?.items);
        }

        if (customerWishlistQuery?.data?.data?.customer?.wishlist) {
            setWishlistId(customerWishlistQuery.data.data.customer.wishlist.id);
        }

    }, [
        productAttributesQuery.data,
        productDetailsQuery.data,
        productRelatedQuery.data,
        autoRelatedSkusQuery.data,
        autoRelatedProductsQuery.data,
        customerWishlistQuery.data
    ]);

    useEffect(() => {
        if (window.appConfig) {
            setAppConfig(window.appConfig);
        }

        if (window.productInfoConfig) {
            setProductInfoConfig(window.productInfoConfig);
        }

        if (window.attachmentConfig) {
            setConfigAttachments(window.attachmentConfig);
        }

    }, [appConfig, productInfoConfig, configAttachments]);

    return (
        <ProductAttributeContext.Provider value={
            {
                productSku,
                productDescription,
                productAttributes,
                productRelated,
                loading: productAttributesQuery.isLoading || addToWishlistMutation.isLoading || productDetailsQuery.isLoading || productRelatedQuery.isLoading,
                error: productAttributesQuery.error || addToWishlistMutation.error || productDetailsQuery.error || productRelatedQuery.error,
                appConfig,
                productInfoConfig,
                configAttachments,
                upsellProducts,
                relatedProducts,
                crossSellProducts,
                autoRelatedProducts,
                productName,
                description,
                customAttributes,
                categories,
                addToWishlist,
            }
        }>
            {children}
        </ProductAttributeContext.Provider>
    );
};

export const useProductAttributes = () => {
    const context = useContext(ProductAttributeContext);
    if (!context) {
        throw new Error('useProductAttributes must be used within a ProductAttributeProvider');
    }
    return context;
};
