import React from "react";
import {useProductAttributes} from "../../../state";
import {PageTitleSkeleton} from "../Skeleton/PageTitleSkeleton";

export const PageTitle = () => {
    const {customAttributes, productName, loading} = useProductAttributes() || {};
    const h2ProductName = customAttributes?.find(attribute => attribute.attribute_code === 'h2_product_name')?.value;

    if (loading) {
        return <PageTitleSkeleton />;
    }

    return (
        <div className="flex flex-col justify-start gap-1 mb-3 xl:mb-5 bg-cards-bg">
            <h1 className="react-css font-family text-xl xl:text-2xl leading-5 font-bold text-custom-black text-center md:text-left xl:text-left">
                {productName}
            </h1>
            <h2 className="react-css font-family text-xl xl:text-2xl leading-5 font-normal text-custom-black text-center md:text-left xl:text-left">
                {h2ProductName}
            </h2>
        </div>
    );
}
